<template>
  <div class="paypage">
    <div class="paycard">
      <div class="payText">支付金额(元)</div>
      <div class="payNumber">{{ $filters.formatMoney(lastInfo.repayAmount,2) }}</div>
    </div>
    <div class="payLine" @click="goback">
      <div class="lineRight line-rigt-text" v-if="bankSrc == ''">
        请选择银行卡
      </div>
       <!-- <div class="lineRight" v-if="lastInfo">
        <img :src="lastInfo.cardLogo" alt="" class="bankIcon" />
        <div class="bankText">{{ lastInfo.bankName }} ({{ lastInfo.bankCard4 }})</div>
      </div> -->
      <div class="lineRight" v-else>
        <img :src="bankSrc" alt="" class="bankIcon" />
        <div class="bankText">{{ bankName }} ({{ bankIn }})</div>
      </div>
      <div class="lineLeft"></div>
    </div>
    <div class="payfrom">
      <div class="payMobile">
        <div class="mobileText">手机号</div>
        <input
          class="moblieNum"
          readonly
          :value="lastInfo.mobile"
          placeholder="请输入手机号"
        />
      </div>
      <div class="payMobile payoutline">
        <div class="mobileText">验证码</div>
        <input
          class="moblieNum"
          type="number"
          oninput="if(value.length>6)value=value.slice(0,6)"
          v-model="verifyCode"
          @change="changeInput($event)"
          placeholder="填写验证码"
        />
        <div class="mobileCode" @click="GetVerifyCode" v-show="canSendCode">
          {{ verifyTxt }}
        </div>
        <div
          class="mobileCode-min"
          @click="GetVerifyCode"
          v-show="!canSendCode"
        >
          {{ count }}秒
        </div>
      </div>
    </div>
    <!-- 底部 支付协议以及按钮-->
    <div class="payprotocol">
      <div
        :class="checked == true ? 'pro-icon' : 'proIcon-active'"
        @click="gochencked"
      ></div>
      <span class="proText" @click="gourl">同意《快捷支付还款提醒》</span>
    </div>
    <div class="footer">
      <div class="btn" @click="paySub">去支付</div>
    </div>
    <!-- 添加银行卡 -->
    <Modal
      :show="showback"
      @cancel="cancel"
      :showCancel="true"
      class="bankpages"
      :title="'选择收款账户'"
    >
      <!-- <div class='bankmain' .openBankName,item.bankAccount4,item.bankAccount,item> -->
      <div class="modalMain">
        <div class="modalContain">
          <div
            class="modalLine"
            v-for="item in bankList"
            :key="item.bankAccount"
            @click="checkBank(item)"
          >
            <img :src="item.logo" alt="" class="lineIcon" />
            <div class="lineText">
              <div>{{ item.openBankName }}（{{ item.bankAccount4 }}）</div>
              <!-- 银行卡的选中的状态 .openBankName+item.bankAccount4-->
              <div
                :class="[
                  chenckStatus == item.bankAccount4 ? 'checkedIcon' : '',
                ]"
              ></div>
            </div>
          </div>

          <div class="modalLine" @click="addBank">
            <div class="lineBank"></div>
            <div class="lineText">添加新的银行卡</div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </Modal>
  </div>
</template>
<style scoped lang='less' src='./index.less'></style>
<script>
import Modal from '@/components/Modal/Modal.vue'
import { reactive, toRefs, onMounted } from 'vue'
import { GetUrlParams, isEmptyObj, routerTag } from '../../utils/index'
import { Toast } from 'vant'
import API from '../../server/api'
import { Jump } from '@/utils/routh'
import { useStore } from 'vuex'
import {useRoute} from 'vue-router';
export default {
  name: 'Index',
  components: { Modal },
  setup () {
    const $store = useStore()
    const route = useRoute()
    const state = reactive({
      userId: '',
      creditNo: '',
      showback: false, // 银行卡
      periodList: [],
      // chenckStatus:false,//选中的状态
      payAmt: 0, // 还款金额
      bankList: [], // 银行卡
      chenckStatus: null,
      bankAccount: '', // 银行卡号
      bankSrc: '',
      bankName: '',
      bankIn: '',
      verifyCode: '',
      countdown: 60,
      verifyTxt: '点击获取验证码',
      canSendCode: true,
      clientNo: '',
      productName: '',
      clientCell: '',
      periodNos: [],
      timer: null, // 倒计时
      count: '', // 倒计时
      checked: true, // 协议选中
      payType: '', // 还款类型
      lastInfo: {},
      isSettle:false
    })

    // 获取银行卡列表
    const bankinit = async () => {
      const result = await API.seecardList({
        creditNo: state.creditNo,
        userId: state.userId,
        loanNo: state.loanNo
      });
      if(result.code==200){
      state.bankList = result.result;
      }
      console.log(state.bankList, '银行卡')
    }
    const checkBank = (item) => {
      state.chenckStatus = item.bankAccount4
      state.bankSrc = item.logo
      state.bankName = item.openBankName
      state.bankIn = item.bankAccount4
      state.bankAccount = item.bankAccount
      state.showback = false
    }
    // 获取验证码
    const GetVerifyCode = async () => {
      const { bankAccount } = state
      if (bankAccount == '') {
        Toast('请选择银行卡')
        return false
      }
      const result = await API.backSms({
        isSettle: state.isSettle,
        loanNo: state.loanNo,
        bankCard: bankAccount // 银行卡
      })
      console.log(result, 'result')
      if (result) {
        countdowns()
      } else {
        Toast('获取验证码失败')
        return false
      }
      // console.log(JSON.stringify(result),774);
    }
    // 验证码倒计时
    const countdowns = () => {
      const TIME_COUNT = 60
      if (!state.timer) {
        state.count = TIME_COUNT
        state.canSendCode = false
        state.timer = setInterval(() => {
          if (state.count > 0 && state.count <= TIME_COUNT) {
            state.count--
          } else {
            state.canSendCode = true
            clearInterval(state.timer)
            state.timer = null
          }
        }, 1000)
      }
    }
    // 获取验证码的input输入值
    const changeInput = (e) => {
      console.log(e.target.value, 33)
      if (e.target.value == '' || e.target.value.length > 6) {
        Toast('请输入正确的验证码')
        return false
      }
      state.verifyCode = e.target.value
    }
    // 还款提交
    const paySub = async () => {
      if (state.bankAccount == '') {
        Toast('请选择银行卡')
        return false
      }
      if (!state.verifyCode) {
        Toast('请先获取短信验证码')
        return false
      }
      if (state.checked == false) {
        Toast('请同意支付还款协议')
        return false
      }
      const result = await API.backMoney({
        loanNo: state.loanNo,
        isSettle: state.isSettle,
        bankCard: state.bankAccount,
        periods: state.lastInfo.list, // 期数列表
        verifyCode: state.verifyCode // 验证码
      })
      console.log(result, '还款结果')
      if (result) {
        Toast.success('提交成功')
        setTimeout(function () {
          // Jump(`${routerTag}payResult`)
            const pathUrl=`${routerTag}payResult`
            const newPath= `/pages/websrc/websrc?url=${pathUrl}`
            APP.JUMP(newPath)
        }, 1000)
      }
    //   if (result.repay.status == 1) {
    //     const payResUrl = routerTag + "payResult";
    //     APP.JUMP_TO(payResUrl, "支付完成");
    //   } else {
    //     Toast(result.repay.remark);
    //     return false;
    //   }
    }
    // 协议选中
    const gochencked = () => {
      state.checked = !state.checked
    }
    const gourl = () => {
      Jump('https://file.jqtianxia.com/file/wage/payAgreement.html')
    }
    // 银行卡弹框
    const goback = () => {
      state.showback = true
      bankinit()
    }
    const cancel = (show) => {
      state.showback = show
      state.chenckStatus = false
    }
    const confirm = (show) => {
      state.showback = show
    }
    // 添加新的银行卡
    const addBank = () => {
      state.showback = false
      // const paybankUrl = routerTag + 'addAccount'
      Jump(`${routerTag}addAccount?num=2`);   
      // Jump(paybankUrl, '添加银行卡')
    }

    onMounted(() => {
      state.creditNo = localStorage.getItem('creditNo')
      state.userId = localStorage.getItem('userId')
      state.loanNo = localStorage.getItem('loanNos')
      state.periodList= localStorage.getItem('periodList')
      // const newData = periodLists.filter((item,index,array)=>{
      //  return array.indexOf(item)===index
      // })
        // console.log(state.periodList,'哈哈哈');
      const lastInfo = localStorage.getItem('backResult')
      state.lastInfo = JSON.parse(lastInfo)
      console.log(state.lastInfo, '信息')
      const url = route.fullPath
      const num = url.substr(-1, 1)
      if(num==1){
        //全部结清
        state.isSettle=true
      }
      bankinit()
      // APP.SET_TITLE('支付')
    })

    return {
      ...toRefs(state),
      goback,
      checkBank,
      cancel,
      confirm,
      GetVerifyCode,
      countdowns,
      paySub,
      addBank,
      changeInput,
      gochencked,
      gourl
    }
  }
}
</script>
